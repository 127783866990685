<template>
  <figure class="regImg">
    <img class="regImgIcon" ref="qrcode" :src="qrCode" />
    <div class="downloadBox" v-if="isApp && platform == 'android'">
      <div class="text">{{ $t.loginInterpret.saveText }}</div>
    </div>
    <div class="downloadBox" @click="download">
      <div class="button">{{ $t.loginInterpret.download }}</div>
    </div>
    <div class="downloadBox" @click="onCopy">
      <div class="button">{{ $t.loginInterpret.copyText }}</div>
    </div>
  </figure>
</template>
<script>
import { isAPP, getPlatform } from "@/plugins/environment/type.js";

export default {
  name: "Invite",
  data() {
    return {
      isApp: isAPP(),
      platform: getPlatform(),
      baseUrl:
        process.env.NODE_ENV === "development"
          ? "http://192.168.0.56:3500/"
          : "https://shop.vancheen.com/",
      invCode: "",
      qrCode: "",
    };
  },
  mounted() {
    const { Code } = this.$route.query;
    if (Code) {
      this.invCode = Code;
      this.getInvcode();
    }
  },
  methods: {
    onCopy() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.clickCopy, {
        text: this.baseUrl + "web/auth/register?InviteCode=" + this.invCode,
        alertText: this.$t.copySuccess,
      });
    },
    download() {
      let e = this.$refs.qrcode;
      this.$commonEnv.commonAction(this.$commonEnv.actionType.downloadImage, {
        element: e,
        successText: this.$t.saveSuccess,
        failText: this.$t.saveFail,
      });
    },
    getInvcode() {
      let param = {
        WebSiteUrl:
          this.baseUrl + "web/auth/register?InviteCode=" + this.invCode,
        FileType: 2,
      };
      this.$api.login
        .getQrCodeByBase64(param)
        .then((res) => {
          this.qrCode = "data:image/jpg;base64," + res.data;
        })
        .catch((err) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: err.message,
            type: "danger",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.regImg {
  width: 100%;
  margin: 0 auto;
  position: relative;
  .regImgIcon {
    width: 100%;
  }
  .downloadBox {
    width: 80%;
    margin: 5% auto;
    text-align: center;
    .button {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border-radius: 3rem;
      padding: 0.8rem 0;
      text-align: center;
      background: linear-gradient(left, #71d283, #01aaa3);
      color: #ffffff;
      font-size: 1rem;
      line-height: 1;
    }
    .text {
      color: #01aaa3;
      font-weight: bold;
    }
  }
}
</style>